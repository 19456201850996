const signUpBtn = document.querySelector('.signin-btn-worktrial');
const flwDesignBtn = document.querySelector('.flw-design-btn-worktrial');
const flwWorkBtn = document.querySelector('.flw-work-btn-worktrial');
const flwControlBtn = document.querySelector('.flw-control-btn-worktrial');

const addTrackingEvent = (action, category, label) => {
  gtag('event', action, {
    'event_category': category,
    'event_label': label,
    'value': 1
  })
}

signUpBtn.addEventListener('click', () => {
  addTrackingEvent('click', 'Button - Sign In - Work Trial Journey', 'Work Trial SignIn Button');
});

flwDesignBtn.addEventListener('click', () => {
  addTrackingEvent('click', 'Button - Flowable Design - Work Trial Journey', 'Work Trial Flowable Design Button');
});

flwWorkBtn.addEventListener('click', () => {
  addTrackingEvent('click', 'Button - Flowable Work - Work Trial Journey', 'Work Trial Flowable Work Button');
});

flwControlBtn.addEventListener('click', () => {
  addTrackingEvent('click', 'Button - Flowable Control - Work Trial Journey', 'Work Trial Flowable Control Button');
});